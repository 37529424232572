<script lang="ts" setup>
const { isOpen } = useTaoModals();
const { unreadNotifications, isActiveAnimation, showNotificationPopup, openPopup } = useNotificationCenter({ isOpen });

useHead({
	bodyAttrs: {
		class: { "overlay-hidden": showNotificationPopup }
	}
});
</script>
<template>
	<div
		class="notification-center"
		data-tid="notification-center"
		:class="{ active: isActiveAnimation }"
		@click="openPopup"
	>
		<NuxtIcon class="icon" name="24/notification-mail" filled />
		<AText
			v-if="unreadNotifications?.length"
			as="div"
			variant="tallinn"
			:modifiers="['bold']"
			class="unread-count text-coro"
		>
			{{ unreadNotifications?.length }}
		</AText>
	</div>
</template>
<style lang="scss">
body.overlay-hidden {
	overflow: hidden;
}
</style>
<style scoped lang="scss">
.notification-center {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.icon {
		color: var(--neutral);
	}

	&.active {
		.icon {
			animation: mail-rotate 4s infinite;
		}
	}
}

.icon {
	font-size: 24px;
	line-height: 1;

	&:deep(svg) {
		margin-bottom: 0;
	}
}

.unread-count {
	position: absolute;
	top: -2px;
	right: -6px;
	border-radius: 50%;
	width: 12px;
	height: 12px;
	background-color: var(--camaguey);
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

@keyframes mail-rotate {
	0% {
		transform: rotate(0deg);
	}
	80% {
		transform: rotate(0deg);
	}
	85% {
		transform: rotate(-10deg);
	}
	95% {
		transform: rotate(10deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
</style>
